@font-face {
  font-family: Vafle Classic;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://1884403144.rsc.cdn77.org/dist/assets/default/fonts/vafle_classic.woff2") format("woff2"), url("https://1884403144.rsc.cdn77.org/dist/assets/default/fonts/vafle_classic.woff") format("woff");
}

@font-face {
  font-family: Vafle Condensed;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://1884403144.rsc.cdn77.org/dist/assets/default/fonts/vafle_condensed.woff") format("woff");
}

@font-face {
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("https://1884403144.rsc.cdn77.org/dist/assets/default/fonts/LatoLatin-Regular.woff2") format("woff2"), url("https://1884403144.rsc.cdn77.org/dist/assets/default/fonts/LatoLatin-Regular.woff") format("woff");
}

.vp-player .vp-logo .vp-logo-top-right, .vp-player .vp-logo .vp-logo-top-left, .vp-player .vp-logo .vp-logo-bottom-right, .vp-player .vp-logo .vp-logo-bottom-left {
  width: auto;
}

.vp-player .vp-big-play-button .vp-icon-play:before {
  content: url("https://img2.cncenter.cz/images/isportcz/dist/svg/play.svg?v=2");
  display: block;
}

.vp-player.vp-size-breakpoint-2 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-3 .vp-big-play-button .vp-icon-play:before {
  width: 30px;
}

.vp-player.vp-size-breakpoint-4 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-5 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-6 .vp-big-play-button .vp-icon-play:before {
  width: 60px;
}

.vp-player.vp-size-breakpoint-7 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-8 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-9 .vp-big-play-button .vp-icon-play:before, .vp-player.vp-size-breakpoint-10 .vp-big-play-button .vp-icon-play:before {
  width: 90px;
}

.vp-player.vp-float-animation {
  z-index: 999 !important;
}

#vp-floating-container .vp-big-play-button .vp-icon-play:before {
  width: 30px;
}

.vp-player--loading {
  aspect-ratio: 16 / 9;
  color: #fff;
  background: #000;
  place-content: center;
  align-items: center;
  display: flex;
}

.vp-video-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  font-family: arial, helvetica, sans-serif;
  position: relative;
}

.vp-video-wrapper .vp-video-wrapper--content .vp-display-container, .vp-video-wrapper .vp-video-wrapper--content .vp-reset-text, .vp-video-wrapper .vp-video-wrapper--content .vp-reset {
  vertical-align: middle;
}

.vp-video-wrapper .vp-skip {
  border: 1px solid #333;
  padding: .5em;
}

.vp-video-wrapper--content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  container-type: inline-size;
}

.vp-banner {
  width: auto !important;
  height: auto !important;
}

.vp-wrapper .vp-logo .vp-second-logo {
  width: auto;
  height: auto;
}

.vp-flag-floating .vp-wrapper {
  border: 5px solid #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .25);
  max-width: 300px !important;
}

.vp-flag-floating .vp-wrapper .vp-overlays .vp-plugin-vast {
  display: none;
}

.vp-video-wrapper--poster {
  width: 100%;
  height: 100%;
}

.vp-prestart .vp-content-fade, .vp-ended .vp-content-fade {
  background: none;
  opacity: 0 !important;
}

.online_report .report_item .media .vp-video-mobile-access-dnd a {
  float: none;
  width: auto;
  margin: auto;
}

.vpplayer .vp-banner {
  width: auto;
  height: auto;
}

.vp-display-controls .vp-svg-icon-puse path, .vp-display-controls .vp-svg-icon-replay path, .vp-display-controls .vp-svg-icon-play path {
  display: none;
}

.vp-display-controls .vp-svg-icon-replay, .vp-display-controls .vp-svg-icon-play {
  background-image: url("/images/default/vp-player/play.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.vp-overlay-video-locked-in-time {
  box-sizing: content-box;
  z-index: 20;
  visibility: hidden;
  background-size: contain;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: calc(100% - 96px);
  height: 100%;
  padding: 0 48px;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .7);
}

.vp-overlay-video-locked-in-time__content {
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.vp-overlay-video-locked-in-time__premium {
  align-items: center;
  gap: 16px;
  display: flex;
}

.vp-overlay-video-locked-in-time__premium.logo-isport .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-reflex .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-e15 .vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium.logo-auto .vp-overlay-video-locked-in-time__magazine {
  width: 110px;
  height: 40px;
}

.vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__magazine {
  width: 150px;
  height: 20px;
}

.vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__divider, .vp-overlay-video-locked-in-time__premium.logo-premium .vp-overlay-video-locked-in-time__premium-logo {
  display: none;
}

.vp-overlay-video-locked-in-time__magazine, .vp-overlay-video-locked-in-time__premium-logo {
  line-height: 0;
}

.vp-overlay-video-locked-in-time__divider {
  background: #e7e7e7;
  width: 1px;
  height: 16px;
}

.vp-overlay-video-locked-in-time__info {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.vp-overlay-video-locked-in-time__titles {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  display: flex;
}

.vp-overlay-video-locked-in-time__title {
  color: #fff;
  font-family: Vafle Condensed, arial, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.vp-overlay-video-locked-in-time__subtitle {
  color: #fff;
  font-family: arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.vp-overlay-video-locked-in-time__button {
  color: #fff;
  cursor: pointer;
  background: #df0028;
  border: 2px solid #df0028;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 150px;
  height: 52px;
  font-family: Lato, sans-serif;
  display: flex;
}

.vp-overlay-video-locked-in-time__button:hover {
  background: #af0523;
  border: 2px solid #af0523;
}

a.vp-overlay-video-locked-in-time__link {
  letter-spacing: .18px;
  padding: 12px 24px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: #fff !important;
  text-decoration: none !important;
}

.vp-overlay-video-locked-in-time__login {
  align-items: center;
  gap: 8px;
  display: flex;
}

.vp-overlay-video-locked-in-time__login-info {
  color: #fff;
  font-family: arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.vp-overlay-video-locked-in-time__login-link {
  font-family: arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-decoration-line: underline;
  color: #fff !important;
}

.vp-overlay-video-locked-in-time__login-link:hover {
  text-decoration: none;
}

.animate-pulse-on-black-bg {
  animation: 2s infinite pulse-animation-on-black-bg;
}

@keyframes pulse-animation-on-black-bg {
  0% {
    box-shadow: 0 0 rgba(255, 255, 255, .2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}

@container (width <= 680px) {
  .vp-overlay-video-locked-in-time {
    width: calc(100% - 96px);
    padding: 0 48px;
  }

  .vp-overlay-video-locked-in-time__content {
    gap: 24px;
  }

  .vp-overlay-video-locked-in-time__info {
    gap: 16px;
  }

  .vp-overlay-video-locked-in-time__titles {
    gap: 0;
  }

  a.vp-overlay-video-locked-in-time__link {
    padding: 8px 10px;
    font-size: 16px;
    line-height: 24px;
  }

  .vp-overlay-video-locked-in-time__button {
    width: 103px;
    height: 40px;
  }
}

@container (width <= 560px) {
  .vp-overlay-video-locked-in-time {
    width: calc(100% - 48px);
    padding: 0 24px;
  }

  .vp-overlay-video-locked-in-time__content {
    gap: 16px;
  }

  .vp-overlay-video-locked-in-time__premium {
    display: none;
  }

  .vp-overlay-video-locked-in-time__info {
    gap: 8px;
  }

  .vp-overlay-video-locked-in-time__titles {
    gap: 0;
  }

  .vp-overlay-video-locked-in-time__title {
    font-size: 18px;
    line-height: 24px;
  }

  .vp-overlay-video-locked-in-time__subtitle, a.vp-overlay-video-locked-in-time__link {
    font-size: 14px;
    line-height: 20px;
  }

  .vp-overlay-video-locked-in-time__button {
    width: 103px;
    height: 32px;
  }

  .vp-overlay-video-locked-in-time__login-info {
    font-size: 14px;
    line-height: 20px;
  }

  .vp-overlay-video-locked-in-time__login-link {
    padding: 8px;
    font-size: 14px;
    line-height: 20px;
  }
}

@container (width <= 328px) {
  .vp-overlay-video-locked-in-time {
    width: calc(100% - 32px);
    padding: 0 16px;
  }

  .vp-overlay-video-locked-in-time__content {
    gap: 16px;
  }

  .vp-overlay-video-locked-in-time__info {
    gap: 8px;
  }
}

.vp-video-mobile-access-dnd--overlayer {
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, .8);
  width: 100%;
  padding: 2% 0;
  font-family: arial, helvetica, sans-serif;
  position: relative;
  bottom: 0;
  overflow: hidden;
}

.vp-video-mobile-access-dnd .vp-video-access-dnd--play {
  top: calc(50% - 28px);
}

.vp-mobile.vp-size-breakpoint-4 .vp-big-play-button .vp-icon-play:before, .vp-mobile.vp-size-breakpoint-5 .vp-big-play-button .vp-icon-play:before, .vp-mobile.vp-size-breakpoint-6 .vp-big-play-button .vp-icon-play:before {
  height: 120px;
}

.body--article #article article i[class^="vp-icon-"], .body--article #article article em[class*=" vp-icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: "icomoon_1.2.0", sans-serif !important;
}

